import axios from 'axios';
let baseUrl = 'https://www.qipeiban.com/'
// let baseUrl ='http://192.168.11.100:8080'
// 设置请求时间
axios.defaults.timeout = 60000;

export default (url, data, method) => {
        if(method=="POST"){
            return axios({
                url: baseUrl + url,
                data: data,
                method: method,
                headers: {
                    'Content-Type': 'application/json',//设置请求头请求格式为JSON
                },
            })
        }else{
            return axios({
                url: baseUrl + url,
                params: data,
                method: method,
                headers: {
                    'Content-Type': 'application/json',//设置请求头请求格式为JSON
                },
            })
        }
}
		


