import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
const routes = [
  {
    path: '/',
    name: 'about',
    redirect: '/home',//设置默认指向的路径
    component: function () { return import( '../views/about.vue') },
    children:[
        {
            path: '/home',
            name: 'home',
            meta: {
                title: '首页'
            },
            component: function () { return import( '../views/home.vue') },
        },
        {
            path: '/Evaluation',
            name: 'Evaluation',
            meta: {
                title: '职业测评'
            },
            component: function () { return import( '../views/Evaluation.vue') },
        },
        {
            path: '/course',
            name: 'course',
            meta: {
                title: '课程培训'
            },
            component: function () { return import( '../views/course.vue') },
        },
        {
            path: '/courselist',
            name: 'courselist',
            meta: {
                title: '课程列表'
            },
            component: function () { return import( '../views/courselist.vue') },
        },
        {
            path: '/coach',
            name: 'coach',
            meta: {
                title: '咨询辅导'
            },
            component: function () { return import( '../views/coach.vue') },
        },
        {
            path: '/PrivateBoard',
            name: 'PrivateBoard',
            meta: {
                title: '企陪伴私董会'
            },
            component: function () { return import( '../views/footLink/PrivateBoard.vue') },
        },
        {
            path: '/RoyalMan',
            name: 'RoyalMan',
            meta: {
                title: '御才有道'
            },
            component: function () { return import( '../views/footLink/yucai.vue') },
        },
        {
            path: '/application',
            name: 'application',
            meta: {
                title: '企业应用'
            },
            component: function () { return import( '../views/application.vue') },
        },
        {
            path: '/agreement',
            name: 'agreement',
            meta: {
                title: '服务协议'
            },
            component: function () { return import( '../views/footLink/agreement.vue') },
        },
        {
            path: '/help',
            name: 'help',
            meta: {
                title: '帮助中心'
            },
            component: function () { return import( '../views/footLink/help.vue') },
        },
        {
            path: '/human',
            name: 'human',
            meta: {
                title: '人力资源咨询'
            },
            component: function () { return import( '../views/footLink/human.vue') },
        },
        {
            path: '/organization',
            name: 'organization',
            meta: {
                title: '组织流程咨询'
            },
            component: function () { return import( '../views/footLink/organization.vue') },
        },
        {
            path: '/achievements',
            name: 'achievements',
            meta: {
                title: '薪酬绩效咨询'
            },
            component: function () { return import( '../views/footLink/achievements.vue') },
        },
        {
            path: '/strategy',
            name: 'strategy',
            meta: {
                title: '战略规划咨询'
            },
            component: function () { return import( '../views/footLink/strategy.vue') },
        },
        {
            path: '/customized',
            name: 'customized',
            meta: {
                title: '定制服务'
            },
            component: function () { return import( '../views/footLink/customized.vue') },
        },
        {
            path: '/journal',
            name: 'journal',
            meta: {
                title: '定制服务'
            },
            component: function () { return import( '../views/footLink/journal.vue') },
        }
    ]
  },
 
]


const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})
//设置默认标题
router.beforeEach(async(to, form, next) => {
  if (to.meta.title) {
      document.title = to.meta.title
  } else {
      document.title = '企陪伴' // 默认title的样式
  }
  next()
}) 
router.afterEach((to, from, next) => {
  window.scrollTo(0, 0)
})
export default router
