import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

Vue.use(ElementUI);

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: function (h) { return h(App) }
}).$mount('#app')

import request from './libs/ajax'
Vue.prototype.$ajax = request

import * as Echarts from 'echarts';
Vue.prototype.$echarts = Echarts

import VueWechatTitle from 'vue-wechat-title'
Vue.use(VueWechatTitle)